exports.components = {
  "component---src-components-single-blog-page-js": () => import("./../../../src/components/singleBlogPage.js" /* webpackChunkName: "component---src-components-single-blog-page-js" */),
  "component---src-components-single-work-page-js": () => import("./../../../src/components/singleWorkPage.js" /* webpackChunkName: "component---src-components-single-work-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-thank-you-js": () => import("./../../../src/pages/design-thank-you.js" /* webpackChunkName: "component---src-pages-design-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

